import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { API_PREFIX, URL } from "../utils/constants";
import { getFromStorage, clearStorage } from "../utils/utility";

const API = axios.create({
  baseURL: API_PREFIX,
  timeout: 60000, // Now all requests using this instance will wait 60 seconds before timing out
});
// Set default headers
API.defaults.headers.get["Accept"] = "application/json";
API.defaults.headers.post["Accept"] = "application/json";
API.defaults.headers.post["Content-Type"] = "application/json";

// Request interceptor to add Authorization token
API.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const clientAccessToken = getFromStorage("clientAccessToken");
  const currentClient = getFromStorage("ifpCurrentClient");
  if (clientAccessToken && config) {
    config.headers.Authorization = `Bearer ${clientAccessToken}`;
  }
  if (currentClient) {
    config.headers.clientId = currentClient;
  }
  return config;
});

// Response interceptors for success and error handling
API.interceptors.response.use(
  (response: AxiosResponse) => responseSuccessHandler(response),
  (error: AxiosError) => responseErrorHandler(error)
);

// Success handler - process successful responses
const responseSuccessHandler = (response: AxiosResponse<unknown>): AxiosResponse => {
  return response;
};

// Error handler - process error responses
const responseErrorHandler = (error: AxiosError): Promise<never> => {
  const { code, response } = error;

  // Handle request timeout error
  if (code === "ECONNABORTED") {
    console.error("Request timeout: ", error.message);
    return Promise.reject(error);
  }

  if (response) {
    console.log("<>err<>", response.status);

    // Handle 401 Unauthorized error (clear storage and redirect to login)
    if (response.status === 401) {
      const url = response.config.url === "/api/clientarea/User/AuthViaAzureAD" ? URL.NO_ACCESS : URL.LOGIN;

      clearStorage();
      // Use setTimeout to defer the redirect action
      setTimeout(() => {
        window.location.replace(url);
      }, 0); // Schedule redirect to happen after the promise rejection
      return Promise.reject(error); // Return rejected promise
    }

    // Handle 403 Forbidden error (redirect to no-access page)
    if (response.status === 403) {
      // Use setTimeout to defer the redirect action
      setTimeout(() => {
        window.location.replace(URL.NO_ACCESS);
      }, 0); // Schedule redirect to happen after the promise rejection
      return Promise.reject(error); // Return rejected promise
    }
  } else {
    // If the error does not contain a response (i.e., network error)
    console.error("Error without response: ", error.message);
  }

  return Promise.reject(error);
};

export default API;
