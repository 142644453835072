import { FC, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { Layout, Menu, Select, Typography } from "antd";
import { MenuOutlined, PoweroffOutlined } from "@ant-design/icons";

import PAGES from "../../utils/pages";
import { AppContext } from "../../Context";
import { IContext } from "../../interfaces/common";
import AuthService from "../../services/auth.service";
import { getActivityLogs } from "../../utils/utility";
import { IClientStore } from "../../interfaces/clients";
import ClientService from "../../services/client.service";
import DreamDepartmentService from "../../services/dreamDepartment.service";
import { clearStorage, getFromStorage, setInStorage } from "../../utils/utility";

const { Text } = Typography;
const { Header } = Layout;

const TopBar: FC = () => {
  const [currentPage, setCurrentPage] = useState(location.pathname);
  const clientsStore = getFromStorage("ifpClients");
  const currentClient = getFromStorage("ifpCurrentClient");
  const clients = clientsStore ? JSON.parse(clientsStore) : [];
  const [appData, setAppData] = useContext(AppContext);

  useEffect(() => {
    (async () => {
      await getActivityLogs();
      await getDreamDepartmentCount();
      await getKeyFilesCount();
    })();
  }, []);

  useEffect(() => {
    if (currentPage === "/key-files") {
      setAppData((prevData: IContext) => ({
        ...prevData,
        keyFiles: 0,
      }));
    }
  }, [currentPage]);

  const getDreamDepartmentCount = async () => {
    const response = await DreamDepartmentService.getCount();
    if (response?.status === 200) {
      setAppData((prevData: IContext) => ({
        ...prevData,
        dreamDepartments: response.data,
      }));
    }
  };

  const getKeyFilesCount = async () => {
    const response = await ClientService.countKeyFiles();
    if (response?.status === 200) {
      setAppData((prevData: IContext) => ({
        ...prevData,
        keyFiles: response.data,
      }));
    }
  };

  const logout = async () => {
    setCurrentPage(location.pathname);
    try {
      if (window.confirm("Are you sure you want to logout?")) {
        await AuthService.msalLogout();
        clearStorage();
      }
    } catch (err: unknown) {
      // eslint-disable-next-line no-console
      console.log("error logging out: " + err);
    }
  };

  const switchClient = (value: string) => {
    setInStorage("ifpCurrentClient", value);
    const url = location.pathname;
    // if (location.pathname.includes("/key-files")) {
    //   url = `${url}/key-files`;
    // }
    // if (location.pathname.includes("/dream-departments")) {
    //   url = `${url}/dream-departments`;
    // }
    location.replace(url);
    // location.reload();
  };

  return (
    <Header className={`app-header${clients?.length > 1 ? " has-switch-container" : ""}`}>
      {clients?.length > 1 && (
        <div className="switch-client-container">
          <div className="switch-client-wrapper">
            <label>Select client account to view: </label>
            <Select
              defaultValue={currentClient}
              style={{ width: 200 }}
              onChange={switchClient}
              options={clients.map((c: IClientStore) => ({
                value: `${c.id}`,
                label: c.name,
              }))}
            />
          </div>
        </div>
      )}
      <div className="app-container">
        <div className="app-logo" />
        <Menu
          className="app-menu"
          mode="horizontal"
          defaultSelectedKeys={[currentPage]}
          overflowedIndicator={<MenuOutlined />}
        >
          {PAGES.filter(page => page.isPage).map(page => {
            let count = 0;
            if (page.name === "Key Files") {
              count = appData.keyFiles;
            } else if (page.name === "Dream Departments") {
              count = appData.dreamDepartments;
            }
            // console.log(">>> count", page.name, count);
            return (
              <Menu.Item key={page.link} className="app-menu__item">
                <Link to={page.link} onClick={() => setCurrentPage(page.link)}>
                  {page.name}
                  {count ? <span className="count">{count}</span> : <></>}
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item key="logout" className="app-menu__item app-menu__item__logout">
            <Text type="danger" onClick={logout}>
              <PoweroffOutlined /> Logout
              {/* {msalAuth?.name} */}
            </Text>
          </Menu.Item>
        </Menu>
      </div>
    </Header>
  );
};

export default TopBar;
