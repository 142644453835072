import { FC } from "react";

import { Button, Result } from "antd";

import { URL } from "../../utils/constants";
import AuthService from "../../services/auth.service";
import { clearStorage, getFromStorage, setInStorage } from "../../utils/utility";

import "./style.scss";
import useAuth from "../../hooks/useAuth";
import useMsalAuth from "../../hooks/useMsalAuth";

const NoAccess: FC = () => {
  const client = useAuth();
  const msalAuth = useMsalAuth();
  const defaultClient = getFromStorage("ifpDefaultClient");

  if (!client || !msalAuth) {
    window.location.replace(URL.LOGIN);
    return null;
  }

  const handleLogout = async () => {
    await AuthService.msalLogout();
    clearStorage();
  };

  const handleGotToDashboard = async () => {
    if (!defaultClient) {
      await handleLogout();
      return;
    }
    setInStorage("ifpCurrentClient", defaultClient);
    window.location.replace(URL.DASHBOARD);
  };

  const renderExtra = () => {
    if (msalAuth) {
      return (
        <>
          <Button type="primary" onClick={handleLogout} key="logout">
            Logout
          </Button>
          {defaultClient && (
            <Button key="login" onClick={handleGotToDashboard} style={{ width: 220 }}>
              Return to Dashboard
            </Button>
          )}
        </>
      );
    }

    return null;
  };

  return (
    <div className="not-found-page no-access-page">
      <Result
        status="403"
        title="NO ACCESS"
        subTitle="Sorry! You don’t have permissions to view this page.  Please contact your IFP project sponsor to request access."
        extra={renderExtra()}
      />
    </div>
  );
};

export default NoAccess;
